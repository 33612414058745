.event-list-banner {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.event-list-banner > div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  border: 5px solid;
  box-sizing: border-box;
}

.event-list-banner--present > div {
  border-color: rgb(var(--primary-color));
}

.event-list-banner--finished > div {
  border-color: var(--text-one-color);
}
.event-list-banner > div,
.event-list-banner > div > h4 {
  transition: all 0.25s;
}

.event-list-banner > div > img {
  transition: all 0.25s;
}

.event-list-banner > div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.25s;
}

.event-list-banner--present > div::after {
  background-color: rgba(var(--primary-color), 0.4);
}

.event-list-banner--finished > div::after {
  background-color: #ffffff60;
}

@media screen and (min-width: 768px) {
  .event-list-banner > div:hover::after {
    /* background-color: rgba(var(--primary-color), 0.5); */
    transform: translateX(0);
  }
  .event-list-banner > div:hover > h4 {
    /* color: rgba(var(--primary-color)); */
  }
}

.dropdown {
  position: absolute;
  width: max-content;
  background-color: rgb(var(--primary-color));
}

.dropdown__option {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 3em;
  font-size: 0.8rem;
  color: rgb(var(--background-color));
  cursor: pointer;
  font-weight: bold;
  border-bottom: 1px solid rgb(var(--background-color));
}

.dropdown__option:last-child {
  border-bottom: none;
}

.dropdown__option:hover {
  background-color: rgb(var(--background-color));
  color: rgb(var(--primary-color));
}

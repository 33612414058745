.login {
  --input-padding: 0.7rem;
  --input-border-width: 3px;

  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.login > form {
  margin-top: 2rem;
  max-width: 600px;
}

.login--input {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login--submit {
  width: 100% !important;
  cursor: pointer;
}

.login--submit:hover {
  background-color: rgb(var(--background-color));
  color: rgb(var(--primary-color));
}

.login--options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  width: calc(100% - 1rem);
  margin-bottom: 0.3rem;
}

.login--options > a {
  text-decoration: none;
  font-size: 0.8rem;
  color: var(--text-one-color);
}

.login--options > a:hover {
  text-decoration: underline;
}

.login--checkbox {
  margin: 0.5rem 0 !important;
}

.login__forgot {
  text-align: right;
  font-size: 1rem;
  color: var(--text-one-color);
  margin-top: 0.5rem;
}

.photo-gallery {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 3rem;
}

.photo-gallery > img {
  width: 100%;
  min-width: 250px;
  cursor: pointer;
  flex: 1;
}

@media screen and (max-width: 768px) {
  .photo-gallery > img {
    min-width: 170px;
  }
}

.photo-gallery__zoom {
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.911);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding: 0;
  margin: 0;
  gap: 0;
  transition: opacity 0.3s ease-in-out;
}

.photo-gallery__zoom--visible {
  visibility: visible;
  opacity: 100%;
}

.photo-gallery__controls {
  width: 100%;
  height: 100%;
  position: absolute;
}

.photo-gallery__controls > img {
  width: 2rem;
  position: absolute;
  right: 0;
  padding: 1rem;
  cursor: pointer;
  z-index: 1;
}

.photo-gallery__controls > div {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transform: translateY(-50%);
  box-sizing: border-box;
  z-index: 1;
}

.photo-gallery__controls > div > img {
  width: 3rem;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .photo-gallery__controls > div > img {
    background-color: rgba(0, 0, 0, 0.76);
    border-radius: 50%;
    padding: 0.2rem;
  }
}

@media screen and (max-width: 480px) {
  .photo-gallery__controls > div {
    visibility: hidden;
  }
}

.photo-gallery__controls > div > img:nth-child(1) {
  transform: rotate(90deg);
}

.photo-gallery__controls > div > img:nth-child(2) {
  transform: rotate(-90deg);
}

.photo-gallery__carousel {
  height: 100%;
  width: fit-content;
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.photo-gallery__photo-wrapper {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-gallery__photo-wrapper > img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  z-index: 1001;
}

@media screen and (max-width: 1000px) {
  .photo-gallery__photo-wrapper > img {
    max-width: 100%;
    max-height: 100%;
  }
}

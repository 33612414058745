.button {
  position: relative;
  height: fit-content;
  min-width: fit-content;
  width: fit-content;
  overflow: hidden;
  text-wrap: nowrap;
}

.button button {
  width: 100%;
}

.button__double {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  overflow: hidden;
  transition: all var(--hover-transition-time) ease-out;
}

.button--disabled {
  background-color: var(--input-disabled-color);
  border-color: var(--input-disabled-color);
}

@media screen and (min-width: 600px) {
  .button:hover .button__double {
    width: 100%;
  }
}

.file--wrapper {
  width: 100%;
}

.file {
  width: 100%;
  border: 2px dashed rgb(var(--primary-color));
  box-sizing: border-box;
  position: relative;
}

.file--error {
  border-color: var(--warning-color) !important;
}

.file--success {
  border-color: #8bd15c !important;
}

.file--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0.7rem 1rem;
  box-sizing: border-box;
  cursor: pointer;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.file--text > span {
  color: var(--text-one-color);
  font-size: 0.8rem;
  line-height: 1.2em;
  /* min-width: 200px;
  flex-shrink: 1; */
}

.file--text-error > span {
  color: var(--warning-color) !important;
}

.file--text-success > span {
  color: #8bd15c !important;
}

.file--text {
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.file--input {
  display: none;
}

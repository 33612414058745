.teams > h1 {
  margin-bottom: 1rem;
}

.teams__handlebar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 4rem;
  flex-wrap: wrap;
}

.teams__handlebar .btn {
  padding: 0.2rem 1.5rem;
}

.teams__handlebar input {
  flex: 1;
  padding: 0.2rem 0.4rem;
  min-width: 250px;
  font-size: 0.8rem;
}

.teams__handlebar > span {
  color: rgb(var(--primary-color));
  font-size: 0.8rem;
  text-wrap: nowrap;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 0px solid rgb(var(--background-color));
  border-left-width: 2px;
  border-right-width: 2px;
  border-collapse: collapse;
  padding: 0 0.4rem;
  text-align: center;
}

.table tr th {
  height: 2.4rem;
  font-weight: bold;
  user-select: none;
}

.table tr td {
  height: 2rem;
}

.table tr th > div,
.table tr td > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.table tr th > div > img,
.table tr td > div > img {
  height: 1.2rem;
  pointer-events: none;
}

.table tr:nth-child(odd) {
  background-color: var(--secondary-color);
}

.table tr:nth-child(even) {
  background-color: var(--accent-color);
}

.table th,
.table td {
  box-sizing: border-box;
  vertical-align: middle;
}

.table th:first-child,
.table td:first-child {
  padding-left: 0.6rem;
}

.table td {
  font-size: 0.8rem;
}

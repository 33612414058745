.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.notfound h6 {
  /* color: rgb(var(--primary-color)); */
  margin-top: 1rem;
}

.notfound div {
  display: inline-flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding-top: 1rem;
}

.notfound > div > button {
  flex-grow: 1;
}

.event {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event__send-file {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.send-file__teams {
  width: 100%;
  display: inline-flex;
  gap: 0.5rem;
}

.send-file__teams > span {
  color: rgb(var(--background-color));
  background-color: rgb(var(--primary-color));
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
}
